import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// //Leads
import Leads from "../pages/Leads";
import LeadImport from "../pages/Leads/LeadImport";
import LeadDetail from "../pages/Leads/LeadDetail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

//Users
import UsersGrid from "../pages/Users/users-grid";
import UsersList from "../pages/Users/UserList/users-list";
import UsersProfile from "../pages/Users/UsersProfile/users-profile";
import Segment from "../pages/Segment";
import Category from "../pages/Category";
import Resource from "../pages/Resource";
import Reference from "../pages/Reference";
import Dashboard from "../pages/Dashboard";
import Appointment from "../pages/Appointment";
import Reminder from "../pages/Reminder";
import AdminDashboard from "../pages/AdminDashboard";
import Report from "../pages/Report";
import ReportList from "../pages/Report/ReportList";
import IntegrationDefinition from "../pages/IntegrationDefinition";
import PrivacyPolicy from "../pages/templates/PrivacyPolicy";
import TermsAndConditions from "../pages/templates/TermsAndConditions";
import Integration from "../pages/Integration";
import SystemParameter from "../pages/SystemParameter";
import LeadHistory from "../pages/LeadHistory";
import FacebookForm from "../pages/FacebookForm";
import Languages from "../pages/Languages";
import FacebookLoginHistory from "../pages/FacebookLoginHistory";
import UserLoginHistory from "../pages/UserLoginHistory";
import LeadIntegrationDefinition from "../pages/LeadIntegrationDefinition";
import Hospital from "../pages/Hospital";
import Doctor from "../pages/Doctor";
import OperationCategory from "../pages/OperationCategory";
import OperationDefinition from "../pages/OperationDefinition";
import OfferItem from "../pages/OfferItem";
import Offer from "../pages/Offer";
import MedicalOperation from "../pages/MedicalOperation";
import DescriptionTemplate from "../pages/DescriptionTemplate";
import Pages500 from "../pages/Utility/Pages500";
import Pages404 from "../pages/Utility/Pages404";
import WebFormVerified from "../pages/WebFormVerified";
import WebFormEn from "../pages/WebForm/en";
import WebFormTr from "../pages/WebForm/tr";
import WebFormRu from "../pages/WebForm/ru";
import WebFormPt from "../pages/WebForm/pt";
import WebFormDe from "../pages/WebForm/de";
import WebFormFr from "../pages/WebForm/fr";
import WebFormSpecificTr from "../pages/WebFormSpecific/tr";
import WebFormSpecificRu from "../pages/WebFormSpecific/ru";
import WebFormSpecificEn from "../pages/WebFormSpecific/en";
import WebFormSpecificPt from "../pages/WebFormSpecific/pt";
import FormSuccessTr from "../pages/WebForm/formSuccessTr";
import FormSuccessFr from "../pages/WebForm/formSuccessFr";
import FormSuccessDe from "../pages/WebForm/formSuccessDe";
import FormSuccessEn from "../pages/WebForm/formSuccessEn";
import FirmInfo from "../pages/FirmInfo";
import Guard from "../pages/Guard";
import NonAuthLayout from "../components/NonAuthLayout";

const user = JSON.parse(localStorage.getItem("authUser"));
const userCheck = user && user.role && user.role.name === "ROLE_USER";

const authProtectedRoutes = [

  { path: "/dashboard", component: userCheck ? Dashboard : AdminDashboard },
//  { path: "/admin-dashboard", component: AdminDashboard },
  // //profile
  { path: "/profile", component: UserProfile },

  //Leads
  { path: "/leads", component: Leads },
  { path: "/lead-import", component: LeadImport },
  { path: "/lead-detail", component: LeadDetail },

  //segments
  { path: "/segments", component: Segment },

  //category
  { path: "/categories", component: Category },

  //resources
  { path: "/resources", component: Resource },

  //references
  { path: "/references", component: Reference },

  //forms
  { path: "/forms", component: FacebookForm },

  //Integration
  { path: "/integration", component: Integration },
  { path: "/integrationDefinition", component: IntegrationDefinition },
  { path: "/lead-integration-definition", component: LeadIntegrationDefinition },

  // Users
  { path: "/users-grid", component: UsersGrid },
  { path: "/users-list", component: UsersList },
  { path: "/users-profile", component: UsersProfile },

  //appointments
  { path: "/appointments", component: Appointment },

  //reminders
  { path: "/reminders", component: Reminder },
  //criteria
  { path: "/reminders/:ntf", component: Reminder },

  //criteria
  { path: "/leads-with-segment/:segmentId", component: Leads },

  //reports
  { path: "/reports", component: Report },
  { path: "/report-list", component: ReportList },

  //system parameters
  { path: "/system-parameter", component: SystemParameter },

  //languages
  { path: "/languages", component: Languages },

  //history
  { path: "/lead-history", component: LeadHistory },
  { path: "/facebook-login-history", component: FacebookLoginHistory },
  { path: "/user-login-history", component: UserLoginHistory },

  //Operation Parameters
  { path: "/hospital", component: Hospital },
  { path: "/doctor", component: Doctor },
  { path: "/operation-category", component: OperationCategory },
  { path: "/operation-definition", component: OperationDefinition },
  { path: "/medical-operation", component: MedicalOperation },
  { path: "/offer-item", component: OfferItem },
  { path: "/offer", component: Offer },
  { path: "/description-template", component: DescriptionTemplate },
  { path: "/firm-info", component: FirmInfo },
  { path: "/guard", component: Guard, layout: NonAuthLayout },

  //templates
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/error-page-500", component: Pages500 },
  { path: "/error-page-404", component: Pages404 },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/web-form-verify", component: WebFormVerified },
  { path: "/web-form/tr", component: WebFormTr },
  { path: "/web-form/en", component: WebFormEn },
  { path: "/web-form/ru", component: WebFormRu },
  { path: "/web-form/pt", component: WebFormPt },
  { path: "/web-form/de", component: WebFormDe },
  { path: "/web-form/fr", component: WebFormFr },
  { path: "/web-form-specific/tr", component: WebFormSpecificTr },
  { path: "/web-form-specific/ru", component: WebFormSpecificRu },
  { path: "/web-form-specific/en", component: WebFormSpecificEn },
  { path: "/web-form-specific/pt", component: WebFormSpecificPt },
  { path: "/form-success/tr", component: FormSuccessTr },
  { path: "/form-success/fr", component: FormSuccessFr },
  { path: "/form-success/de", component: FormSuccessDe },
  { path: "/form-success/en", component: FormSuccessEn },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },

  //templates
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/error-page-500", component: Pages500 },
  { path: "/error-page-404", component: Pages404 }
];

export { authProtectedRoutes, publicRoutes };
